import React, {useEffect, useState} from 'react';
import firebase from '../../firebase'
import './style.scss';

const GalleryContainer = ()=>  {
  const [imageList, setImageList] = useState([]);

  const GetGalleryImages = async () => {
    let ImageLocation = await firebase.storage().ref('Images').listAll().then(list => list.items);

    let imageArray = [];
    await (async () => {
      for await (let image of ImageLocation) {
        if(image){
          let metadata = await firebase.storage().ref(image.location.path_).getMetadata()
          let imageUrl = await firebase.storage().ref(image.location.path_).getDownloadURL()
          Promise.all([metadata, imageUrl]).then(values => 
            imageArray.push({name: values[0].name, date: values[0].timeCreated, url: values[1]})
          );
        }
      }
    })();
    const sortedActivities = imageArray.sort((a, b) => b.date - a.date)
    setImageList(sortedActivities)
  }
  useEffect(() => {
    GetGalleryImages()
  },[])
  
  return (
    <div className="gallery">
      <div className="gallery-container">
        {imageList.map((image, index) => 
        <img className="gallery-image" 
        key={index} 
        src={image.url} 
        alt={'gallery'}/>)}
      </div>
    </div>
  );
}

export default GalleryContainer;
