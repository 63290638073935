import React from 'react';
import './style.scss';

export const CommissionInput = ({title, description, required, inputType, userInput, handleUserInput}) => (
    <div className="comm-option">
        <div className="comm-title">
            {title}
            {required && (<span className="comm-required"> *</span>)}
        </div>
        <div  className="comm-description">{description}</div>
        <input
        name={inputType}
        placeholder={'Enter your ' + title}
        type="text"
        value={userInput}
        onChange={handleUserInput} />
    </div>
)

export const CommissionDescription = ({title, description, required, inputType, userInput, handleUserInput}) => (
    <div className="comm-option">
    <div className="comm-title">
        {title}
        {required && (<span className="comm-required"> *</span>)}
    </div>
    <div className="comm-description">{description}</div>
        <textarea 
        name={inputType}
        id='description'
        placeholder={title}
        type="text"
        value={userInput}
        onChange={handleUserInput} 
        />
  </div>
)

export const CommissionType= ({title, description, required, types, selectedType, handleType}) => (
    <div className="comm-option">
        <div className="comm-title">
            {title}
            {required && (<span className="comm-required"> *</span>)}
        </div>
        <div className="comm-description">{description}</div>
        <div className="comm-type">
            {types.map((type, index) => 
            <CommissionRadio key={index} 
            type={type} 
            typeState={selectedType} 
            handler={handleType}/> 
            )}
        </div>
    </div>
)

export const CommissionRadio = ({type, typeState, handler}) => (
    <div className="comm-img">
        {/* <img src={type.image} alt="comm" /> */}
        <div className="comm-radio">
            <input
            id={type.name}
            value={type.name}
            type="radio"
            checked={typeState === type.name}
            onChange={handler} 
            />
            <div className="comm-type-title">{type.typeDetails}</div>
        </div>
    </div>
)

export const CommissionExtras = ({title, description, required,  extraDetails, userCheckbox, handleUserInput}) => (
    <div className="comm-checkbox">
        <div className="comm-title">
            {title}
            {required && (<span className="comm-required"> *</span>)}
        </div>
        <div className="comm-description">{description}</div>
        {Object.keys(userCheckbox).map((value, index)=> {
            let details = extraDetails.find(detail => detail.name === value)

            return(
            <div key={index} className="comm-checkbox-input">
                <input
                name={value}
                type="checkbox"
                checked={userCheckbox[value]}
                onChange={handleUserInput} />
                {details ? details.typeDetails : ''}   
            </div>
            )}
        )}
    </div>
)