import React, { useState } from 'react';
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import MainContainer from './containers/MainContainer'
import MobileMenu from './components/MobileMenu';
import "./style.scss";


function App() {
  const [selectedNav, setSelectedNav] = useState('shop');
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <div className="App">
      <Navigation 
      selectedNav={selectedNav} 
      setSelectedNav={setSelectedNav}
      mobileMenu={mobileMenu}
      setMobileMenu={setMobileMenu}
      />
      {mobileMenu && (
        <MobileMenu 
        selectedNav={selectedNav} 
        setSelectedNav={setSelectedNav} 
        setMobileMenu={setMobileMenu}/>
      )}

      <MainContainer selectedNav={selectedNav} setSelectedNav={setSelectedNav} />
      <Footer />
    </div>
  );
}

export default App;
