import React from 'react';
import axios from 'axios'
import './style.scss';

const CommissionConfirm = ({userInput, userCheckbox, selectedType, extraDetails, setConfirmPage, handleSubmit}) =>  {
    return(
    <div className={"confirm-container"}>
        <div className={"confirm-window"}>
            <h1>Summary</h1>
            <div className="confirm-itemlist">
                <div className="confirm-item">Email Address: <div className="confirm-details">{userInput.emailAddress} </div></div>
                <div className="confirm-item">Commission Type: <div className="confirm-details">{selectedType.typeDetails} </div></div>
                <div className="confirm-item">Additional Characters: <div className="confirm-details">{userCheckbox.additional === true ? 'Yes $' + extraDetails[0].price + ' (Depending on complexity)' : 'No'} </div></div>
                <div className="confirm-item">Background: <div className="confirm-details">{userCheckbox.background === true ? 'Yes $' + extraDetails[1].price + ' (Depending on complexity)' : 'No'} </div></div>
                <div className="confirm-item">Description: <div className="confirm-details">{userInput.userDescription} </div></div>
                <div className="confirm-item">References: <div className="confirm-details">{userInput.references ? userInput.references  : 'None'} </div></div>
            </div>
            <div className="confirm-buttons">
                <button onClick={() => handleSubmit()}>
                    Submit
                </button>
                <button onClick={() => setConfirmPage(false)}>
                    Cancel
                </button>
            </div>
        </div>
    </div>
    )
}
export default CommissionConfirm;