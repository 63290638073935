import React from "react";
import "./styles.scss";

export const MobileMenu = ({selectedNav, setSelectedNav, setMobileMenu}) => {

  return(
  <div className="mobilemenu">
  <div className="mobilemenu-background" onClick={() => setMobileMenu(false)}/>
    <div className="mobilemenu-container">
      <div className="mobilemenu-header">
        Kuribelle
        <button onClick={() => setMobileMenu(false)}>x</button>
      </div>

      <div className="mobilemenu-option" 
      style={{color: selectedNav === 'shop' ? 'red' : ''}} 
      onClick={() => {
        setSelectedNav('shop')
        setMobileMenu(false)
      }}>
        <img src={'/shopping-cart.png'} alt={'shopping'}/>
        Shop
      </div>

      {/* <div className="mobilemenu-option" 
      style={{color: selectedNav === 'gallery' ? 'red' : ''}} 
      onClick={() => {
        setSelectedNav('gallery')
        setMobileMenu(false)
      }}
      >
      <img src={'/gallery-icon.png'} />
        Gallery
      </div> */}

      <div className="mobilemenu-option" 
      style={{color: selectedNav === 'commission' ? 'red' : ''}} 
      onClick={() => {
        setSelectedNav('commission')
        setMobileMenu(false)
      }}>
      <img src={'/commission-icon.png'} alt={'commission'}/>
        Commission
      </div>

      <div className="mobilemenu-option" 
      style={{color: selectedNav === 'tos' ? 'red' : ''}} 
      onClick={() => {
        setSelectedNav('tos')
        setMobileMenu(false)
      }}>
      <img src={'/commission-icon.png'} alt={'tos'}/>
        Terms of Service
      </div>
    </div>
  </div>
  )
};
