import React from 'react';
import ShopContainer  from '../ShopContainer'
import GalleryContainer from '../GalleryContainer';
import CommissionContainer from '../CommissionContainer';
import TermsOfServiceContainer from '../TermsOfServiceContainer';

import './style.scss';


const MainContainer = ({selectedNav, setSelectedNav})=>  {
    let renderedComponent;
    if (selectedNav === 'shop') {
        renderedComponent = <ShopContainer />
    } else if(selectedNav === 'gallery') {
        renderedComponent = <GalleryContainer />
    } else if(selectedNav === 'commission') {
        renderedComponent = <CommissionContainer setSelectedNav={setSelectedNav} />
    } else if(selectedNav === 'tos') {
      renderedComponent = <TermsOfServiceContainer />
    }
  return (
    <div className="main">
      {renderedComponent}
    </div>
  );
}

export default MainContainer;
