import firebase from 'firebase/app'
import 'firebase/firebase-auth';
import 'firebase/firebase-storage';
import 'firebase/firebase-firestore';
import 'firebase/firebase-analytics';
import 'firebase/firebase-functions';
import axios from 'axios';


  // Initialize Firebase
  axios.get("https://us-central1-kuribelle-6eec1.cloudfunctions.net/firebaseConf").then(res => {
    firebase.initializeApp(res.data);
    firebase.analytics();
  })

  export default firebase;