import React from 'react';
import Carousel from 'react-elastic-carousel';
import './style.scss';

const Product = ({product}) => (
    <div className="product">
        <Carousel>
            {product.images.map(image => 
                <a href={product.url} 
                target="_blank" 
                rel="noopener noreferrer" 
                key={image.listing_image_id}>
                    <img
                    src={image.url_fullxfull}
                    alt="product-img"
                    className="product-image"/>
                </a>
            )}
        </Carousel>
        <div className="product-title">{product.title}</div>
        <div className="product-price">${product.price}</div>
    </div>
)


export default Product;