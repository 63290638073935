import React  from "react";
// import axios from 'axios'
import "./style.scss";

export const Navigation = ({selectedNav, setSelectedNav, setMobileMenu}) => {
  // const [twitchLive, setTwitchLive] = useState(false);

//   useEffect(() =>{
//     axios.get("/twitch").then(res => setTwitchLive(res.data.data[0].is_live))
// },[])


  return(
  <div className="nav-bar">
    <button className="nav-mobile-icon" onClick={() => setMobileMenu(true)}>
      <img src={'/menu_icon_white.png'} alt={'menu_icon'}/>
    </button>

    <div className="nav-center">
      <div className="nav-name">Kuribelle</div>
      <div className="nav-option" style={{color: selectedNav === 'shop' ? 'red' : ''}} 
      onClick={() => setSelectedNav('shop')}>
        <img src={'/shopping-cart.png'} alt={'shopping-cart'}/>
        Shop
      </div>
      {/* <div className="nav-option" style={{color: selectedNav === 'gallery' ? 'red' : ''}} 
      onClick={() => setSelectedNav('gallery')}>
      <img src={'/gallery-icon.png'} />
        Gallery
      </div> */}
      <div className="nav-option" style={{color: selectedNav === 'commission' ? 'red' : ''}} 
      onClick={() => setSelectedNav('commission')}>
      <img src={'/commission-icon.png'} alt={'commission'}/>
        Commission
      </div>
      <div className="nav-option" style={{color: selectedNav === 'tos' ? 'red' : ''}} 
      onClick={() => setSelectedNav('tos')}>
      <img src={'/commission-icon.png'} alt={'TOS'}/>
        Terms of Service
      </div>
    </div>

      <div className="nav-right-option"
      onClick={() => window.open('https://www.twitch.tv/kuribelle')}>
        <div className="nav-twitch">
          <img src={'./twitch-icon.png'} alt="nav-img" />
        </div>
        {/* {twitchLive 
        ? (
          <div className="nav-live">
            Live
          </div>
        )
        : (
          <div className="nav-offline">
            Offline
          </div>
        )} */}
      </div>

  </div>
  )
};
