import React from "react";
import "./style.scss";

export const Footer = () => (
  <div className="footer">

    <div className="option-container-left" >
      <div className="footer-option" onClick={() => window.open('https://www.instagram.com/kuribelle/', '_blank')}>
          <img src={'./instagram-icon.png'} alt="footer-img" />
          <div className="footer-text">Instagram</div>
      </div>
      <div className="footer-option" onClick={() => window.open('https://www.patreon.com/kuribelle/', '_blank')}>
          <img src={'./patreon-icon.png'} alt="footer-img" />
          <div className="footer-text">Patreon</div>
      </div>
      <div className="footer-option" onClick={() => window.open('https://www.twitter.com/kuribelle/', '_blank')}>
          <img src={'./twitter-icon.png'} alt="footer-img" />
          <div className="footer-text">Twitter</div>
      </div>
    </div>

    <div className="option-container-right" >
      <div className="footer-option" onClick={() => window.open('https://www.facebook.com/kuribelle/', '_blank')}>
          <img src={'./facebook-icon.png'} alt="footer-img" />
          <div className="footer-text">Facebook</div>
      </div>
      <div className="footer-option" onClick={() => window.open('https://www.deviantart.com/kuribelle/', '_blank')}>
          <img src={'./deviantart-icon.png'} alt="footer-img" />
          <div className="footer-text">DeviantArt</div>
      </div>
      <div className="footer-option" onClick={() => window.open('https://www.webtoons.com/en/challenge/star-panic-richi/list?title_no=347842', '_blank')}>
          <img src={'./webtoon-icon.png'} alt="footer-img" />
          <div className="footer-text">Webtoon</div>
      </div>
    </div>
  </div>
)
