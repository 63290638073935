import React from 'react';
import './style.scss';

const TermsOfServiceContainer = () => (
    <div className="tos-container">
        {/* <div>
            Thank you for your interest in commissioning me!
            By commissioning me you agree to understand the following:
        </div>

        <div className="tos-title">GENERAL TERMS</div>
        <ul>
            <li>To request a commission, please DM me (Twitter or Instagram) or through email (<strong>ripnicolle@gmail.com</strong>) with the subject “Commission Request”.</li>
            <li>I reserve the right to refuse or cancel a commission.</li>
            <li>The payment is to be done up front through PayPal, using US Dollars ($) as the currency.</li>
            <li> Do NOT send any money before I accept your request!</li>
            <li>I draw in cutesy style and there’re limitations for the poses and/or some details, which will be simplified to fit the style. (Make sure to look through my catalog)</li>
            <li>You are solely responsible for securing the proper rights to the character/s you want the commission to feature. Characters from pop culture and media are accepted.</li>
            <li>An extra design fee applies to those who have no reference images of the subject and would like me to draw from a description or a collage of photographs.</li>
            <li>Keep in mind that the complexity of the subject and/or large/major modifications may incur additional charges upon the commissioner, based on the amount of modifications/additions requested to be made.</li>
            <li>Please be aware that it may take anywhere from 2 days to a month to finish your order. If you have a deadline, please notify me beforehand with the date so I can confirm whether or not I can finish on time before taking the job.</li>
        </ul>
        
        <div className="tos-title">PAYMENT</div>
        <ul>
            <li>All commissions must be paid in full and upfront.</li>
            <li>I will send over an invoice through paypal after discussion and after you have provided your email/paypal address! (OR I will send my paypal email, for you to send the payment to.)</li>
            <li>Once your request has been acepted, commissioner must pay in the span of 48 hours. If not, the order will be canceled.</li>
            <li>You cannot cancel the commission(s) after you have made the payment, there will be NO refunds, unless I am not able to start on the piece for any unforeseeable reason.</li>
            <li>Please mark the payment as “RECEIVED” in your paypal account when you’ve received your comms.</li>
        </ul>

        <div className="tos-title">PROCESS</div>
        <ul>
            <li>Please make sure to include the following when submitting a commission request:</li>
            <div><strong>Commission type:</strong> Headshot Single or Couple, Detailed Chibi, etc.</div>
            <div><strong>Character:</strong> Can be written description, photo, or reference image. Please provide sufficient & appropriate image references.</div>
            <div><strong>Pose:</strong> If you want to leave that up to me, that’s ok too!</div>
            <div><strong>Outfit:</strong> If it’s in the reference image, no need to mention it.</div>
            <div><strong>Expression:</strong> If you want to leave that up to me, that’s ok too!</div>
            <div><strong>Paypal Email:</strong> That I can send your invoice to!</div>
            <li>Sketching process will start after full payment is received.</li>
            <li>I will send over a sketch within a few days for you to request any changes you would like before I continue onto lining. After the lining and coloring process, I will not be able to make any major changes for free (change of pose, outfit changes, adding or removing large elements, etc.) so please make sure you let me know any changes before then! I am able to do things like change facial expression, add accessories, make color changes, etc. Please feel free to ask me if I am able to make changes for you if you are unsure!</li>
            <li>I will be sure to update you with WIPs (sketch, line art, base colours) as often as i can, feedback is very welcomed at this stage and if you’d like to request an update feel free to!  WIPs will be watermarked.</li>
            <li>Without a deadline provided I could take up to 2-4 weeks to finish your commission. But me taking that long is very unlikely, I usually try to complete your commission within a week and a half after accepting the payment! If I think it will take me a bit longer to finish yours, I will let you know and keep you updated.</li>
            <li>Finished drawing will be sent for final review, you can give one or two last feedback to tweak little things, NO MAJOR CHANGES.</li>
            <li>Once I have completed your commission, I will not issue any type of refunds. PLEASE let me know if you would like something changed early on in the process so I can fix it for you. I also want to make sure you are happy and satisfied with your commission!</li>
            <li>After everything is approved, I will send the PNG files of the final artwork to the email provided. You will receive 3 PNG files: one with transparent BG, one with BG, as well as one with BG and watermark for you to post.</li>
        </ul>

        <div className="tos-title">COPYRIGHT and USAGE POLICY</div>
        
        <div className="tos-subtitle">As the commissioner, you are allowed to:</div>
        <ul>
            <li>You may NOT claim the commissioned artworks as YOUR OWN.</li>
            <li>As I maintain all the rights to the commissioned artworks, you as the client, may only post the watermark version with credit given back to my Twitter/Instagram.</li>
            <li>You hold the right to the character if it’s your original character, but not the commission itself.</li>
            <li>Use the art for personal use only.</li>
        </ul>

        <div className="tos-subtitle">As the artist, I’m allowed to:</div>
        <ul>
            <li>Retain the copyright of the commissioned pieces.</li>
            <li>Use the art for professional portfolio and/or online promotion.</li>
            <li>Reserve the right to livestream and record the creation of the artwork, unless we agree differently.</li>
            <li>Post on my social media, with a clarification that it’s a commission!</li>
        </ul>

        <div className="tos-title">COPYRIGHT INFRINGEMENT</div>
        <ul>
            <li>My general commissions are for noncommercial use only! Meaning you CANNOT use your commission for profit. Example: Selling T-shirts, prints, stickers, etc.</li>
            <div>You CAN use my art for: icons and display pictures on social media, Youtube thumbnails or headers, graphics for your stream, etc.</div>
            <li>Do NOT take credit for the artwork if you share it.</li>
            <li>Do NOT erase watermarks from the WIPs and/or the finished artwork.</li>
            <div>If you are interested in commissioning me for commercial work, or bigger projects please email me all of the details of your project and we can discuss it further!</div>
        </ul> */}


<div className="tos-title">Terms of Service - Read Before Commissioning</div>
        <ul>
            <li>
                All commissions are for non-commercial use only. For commissions intended to be used commercially, please contact me directly at: <b>cneunity@gmail.com</b>
            </li>

            <li>
                Commissions cannot be: (a) used to advertise people, businesses, products, or services; (b) mass-produced; or (c) used for profit in any way
            </li>

            <li>
                All commission prices displayed are in United States dollars ( USD ) and will have to be paid through Paypal only
            </li>

            <li>
                Displayed commission prices are subject to change
            </li>

            <li>
                <b>Half of the total price must be paid and fully credited to my PayPal account before I begin working on the commission</b>
            </li>

            <li>
                The advance payment credited to my PayPal account will be refunded only if the commission is cancelled before I begin working on it
            </li>

            <li>
                I reserve the right to cancel a commission and refund any payments already made.  This cancellation may be due to, for example, poor communication or inappropriate behavior
            </li>

            <li>
                The commission sketch, WIPs, and final renders will be sent to the contact information provided ( e.g. Twitter, Discord DMs, email, etc. )
            </li>

            <li>
                All revisions must be confirmed BEFORE the approval of the sketch
            </li>

            <li>
                Major changes ( such as posing, whole outfit, etc. ) requested after the approval of the sketch will be subject to additional charges
            </li>

            <li>
                I do not accept time restricted or rush commissions, but I will try to finish the commission in a timely manner
            </li>

            <li>
                I am working on an ongoing project and personal work so I will be alternating between those and commissions
            </li>

            <li>
                If you post or use the commission for personal, non-commercial purposes anywhere, please credit and link to either of my following accounts: <a href={'https://twitter.com/kuribelle'}>Twitter</a> or <a href={'https://twitch.tv/kuribelle'}>Twitch</a>
            </li>

            <li>
                The commissioned artwork cannot be altered by you without my explicit permission
            </li>

            <li>
                I reserve all rights to my artwork and may use the final commission piece and related process work in my portfolio, social media sites, websites, and other electronic and print publications
            </li>
        </ul>
    </div>
)

export default TermsOfServiceContainer;


