import React, { useState, useReducer } from 'react';
import {CommissionInput, CommissionDescription, CommissionType, CommissionExtras} from '../../components/Commission';
import CommissionConfirm from '../../components/Confirmation/'
import axios from 'axios'
import './style.scss';

const CommissionContainer = ({setSelectedNav})=>  {
  const [confirmPage, setConfirmPage] = useState(false)

  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      emailAddress: "",
      userDescription: "",
      references: "",
    }
  );
  const [userCheckbox, setUserCheckbox] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      additional: false,
      background: false,
    }
  );

  const [selectedType, setSelectedType] = useState("")
  const types =
    [
      {
      name: 'emoteBadge',
      image: './emote_example.png',
      typeDetails: "Emote/Badge - $25",
      price: 25
    },
    {
      name: 'chibi',
      image: './chibi_example.jpg',
      typeDetails: "Chibi - $35",
      price: 35
    },
    {
      name: 'head',
      image: './head_example.jpg',
      typeDetails: "Head - $30",
      price: 30
    },
    {
      name: 'bust',
      image: './bust_example.jpg',
      typeDetails: "Bust - $35",
      price: 35
    },
    {
      name: 'halfBody',
      image: './halfBody_example.jpg',
      typeDetails: "Half Body - $40",
      price: 40
    },
    {
      name: 'fullBody',
      image: './fullBody_example.jpg',
      typeDetails: "Full Body - $45",
      price: 45
    }]

    const extraDetails =
    [{
      name: 'additional',
      typeDetails: "Additional Characters - $25",
      price: 25
    },
    {
      name: 'background',
      typeDetails: "Background - $25",
      price: 25
    },
  ]
    
  
  const handleUserInput = evt => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    const checked = evt.target.checked;
    name === 'additional' || name === 'background'
    ? setUserCheckbox({[name]: checked})
    : setUserInput({[name]: newValue})
  }

  const handleType = e => {
    const typeName = e.target.id;

    if(typeName) {
      const typeObj = types.find(obj => obj.name === typeName);
      setSelectedType(typeObj)
    }
  }

  const handleSubmit = (event) => {
    formSubmission(userInput, userCheckbox, selectedType)
    setUserInput({
      emailAddress: "",
      userDescription: "",
      references: "",
    })
    setUserCheckbox({
      additional: false,
      background: false,
    })
    setSelectedType("")
    event.preventDefault();
 }

 const handleConfirmation = (event) => {
 
  setConfirmPage(true)
  event.preventDefault();

}

  return (
  <div className="container">
    {confirmPage && (
      <CommissionConfirm 
      userInput={userInput} 
      userCheckbox={userCheckbox} 
      selectedType={selectedType}
      extraDetails={extraDetails}
      setConfirmPage={setConfirmPage}
      handleSubmit={handleSubmit}/>
    )}
    <h1 className="commission-title">COMMISSION</h1>
    <div className="commission-header">
        <span>Please be sure to read the</span> <div className="tos-link" onClick={() => setSelectedNav('tos')}> Terms of Service</div> <span>before commissioning me.</span>

        <p>Fill out the forms below and submit. You will be contacted via Email if I accept your submission.</p>
    </div>

    <form className="comm-form" onSubmit={handleConfirmation}>
      <CommissionInput title={'Email'} 
      description={''}
      required={true}
      inputType={'emailAddress'}
      userInput={userInput.emailAddress} 
      handleUserInput={handleUserInput}/>

      <CommissionType title={'Type of Commission'}
      description={'Select a commission type'}
      required={true}
      types={types}
      selectedType={selectedType.name}
      handleType={handleType}/>

      <CommissionExtras title={'Extras'} 
      description={'Prices may vary depending on complexity.'}
      required={false}
      extraDetails={extraDetails}
      userCheckbox={userCheckbox} 
      handleUserInput={handleUserInput}/>

      <CommissionDescription title={'Description'} 
      description={'Describe the commission.'}
      required={true}
      inputType={'userDescription'}
      userInput={userInput.userDescription} 
      handleUserInput={handleUserInput}/>
      
      <CommissionInput title={'References'} 
      description={''} 
      required={false}
      inputType={'references'}
      userInput={userInput.references} 
      handleUserInput={handleUserInput}/>

      
      <input className="comm-submit" 
      type="submit" 
      value="Next" 
      disabled={!userInput.emailAddress || !userInput.userDescription || !selectedType} 
      />
    </form>
  </div>
  );
}

export default CommissionContainer;

const formSubmission = (userInput, userCheckbox, selectedType) => {

  axios({
    url: 'https://formspree.io/mjvpkeeq',
    method: 'post',
    headers: {
      'Accept': 'application/json'
    },
    data: {
      email: userInput.emailAddress,
      commissionType: selectedType.typeDetails,
      description: userInput.userDescription,
      additional: userCheckbox.additional === true ? 'Yes' : 'No',
      background: userCheckbox.background === true ? 'Yes' : 'No',
      references: userInput.references ? userInput.references  : 'None',
    }
  })
  .then(response => { 
    alert("Commission successfully submitted!");
    console.log('Commission successfully submitted!')
  })
  .catch(err => 
    console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
}