import React, { useEffect, useState } from 'react';
import Product from '../../components/Shop/Product';
import axios from 'axios';
import './style.scss';

const ShopContainer = () => {
    const [productList, setProductList] = useState([]);

    useEffect(() =>{
        if(productList.length === 0){
        axios.get('https://us-central1-kuribelle-6eec1.cloudfunctions.net/shop')
        .then(res =>setProductList(res.data))
        }
    },[productList])

    return(
    <div className="shop-container">
        <h1 className="shop-title">SHOP</h1>
        <div className="shop-products-list">
            {productList.length > 0 && (
                productList.map(product => <Product key={product.id} product={product}/>)
            )}
        </div>
    </div>
    )
}

export default ShopContainer;